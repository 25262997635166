// コメントモーダルを表示する
export default function initCommentModal() {
    document.addEventListener('DOMContentLoaded', function() {
        function openModal($el: Element) {
            $el.classList.add('is-active');
        }
        function closeModal($el: Element) {
            $el.classList.remove('is-active');
        }
        function closeAllModals() {
            document.querySelectorAll('.modal.is-active').forEach(function($el) {
                closeModal($el);
            });
        }
        const modalEl = document.getElementById('comment-modal');
        const modalDatetime = modalEl?.querySelector('#comment-modal-datetime');
        const modalStatus = modalEl?.querySelector('#comment-modal-status');
        const modalScore = modalEl?.querySelector('#comment-modal-score')
        const modalComment = modalEl?.querySelector('#comment-modal-comment');
        if (modalEl && modalDatetime && modalStatus && modalScore && modalComment) {
            document.querySelectorAll('.show-comment').forEach(function($el) {
                $el.addEventListener('click', function(e) {
                    const datetime = $el.querySelector('.column-datetime');
                    if (datetime) {
                        modalDatetime.innerHTML = datetime.innerHTML;
                    } else {
                        modalDatetime.innerHTML = '-';
                    }
                    const status = $el.querySelector('.column-status');
                    if (status) {
                        modalStatus.innerHTML = status.innerHTML;
                    } else {
                        modalStatus.innerHTML = '-';
                    }
                    const score = $el.querySelector('.column-score')
                    if (score) {
                        modalScore.innerHTML = score.innerHTML;
                    } else {
                        modalScore.innerHTML = '-';
                    }
                    const comment = $el.querySelector('.column-comment');
                    if (comment) {
                        modalComment.innerHTML = comment.innerHTML;
                    } else {
                        modalComment.innerHTML = '<span class="has-text-gray">コメントはありません</span>';
                    }
                    openModal(modalEl);
                });
            });
        }
        document.querySelectorAll('.modal-background, .modal-close, .modal-card-head, .delete, .modal-card-foot .button').forEach(function($el) {
            const $closest = $el.closest('.modal');
            if ($closest) {
                $el.addEventListener('click', function(e) {
                    closeModal($closest);
                });
            }
        });
        document.addEventListener('keydown', function(e) {
            if (e.key === 'Escape') {
                closeAllModals();
            }
        });
    });
}
