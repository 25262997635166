type CascadeOptionItem = {
    value: string;
    label: string;
    selected?: boolean;
};

type CascadeOptions = {
    [key: string]: CascadeOptionItem[];
};

function setOptions($el: HTMLSelectElement, options: CascadeOptionItem[], value?: string) {
    const children = options.map((item) => {
        if (value && value !== '') {
            if (item.value === value) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        }
        return `<option value="${item.value}" ${item.selected ? 'selected' : ''}>${item.label}</option>`;
    });
    $el.innerHTML = children.join('');
}

export default  function initCascadeSelect() {
    document.addEventListener('DOMContentLoaded', function() {
        document.querySelectorAll<HTMLSelectElement>('select[data-cascade]').forEach(function($el) {
            const targetName = $el.dataset.cascade;
            const o = $el.dataset.cascadeOptions;
            const value = $el.dataset.value;
            if (!(targetName && o && value)) return;
            const options: CascadeOptions = JSON.parse(o);
            const $target = document.querySelector<HTMLSelectElement>(`select[name="${targetName}"]`);
            if (!$target) return;
            setOptions($el, options[$target.value], value);

            // カスケード元のセレクトボックスの値が変更されたら、カスケード先のセレクトボックスの選択肢を変更する
            $target.addEventListener('change', function(e) {
                const value = $target.value;
                if (options[value] === undefined) return;
                setOptions($el, options[value]);
            });
        });
    });
}
