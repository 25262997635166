export default function toggleHamburgerMenu() {
    document.addEventListener('DOMContentLoaded', () => {

        // 全てのnavbar-burgerを取得
        const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    
        // clickした際の処理を追加する
        $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {
    
            // data-target で表示切替を行う要素を取得する
            const target = el.dataset.target;
            const $target = document.getElementById(target);
    
            el.classList.toggle('is-active');
            $target?.classList.toggle('is-active');  
        });
        });
    });
}