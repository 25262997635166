import tippy from 'tippy.js';
tippy('[data-tippy-content]');

import initCommentModal from '../../src/comment-modal';
initCommentModal();

import initCascadeSelect from '../../src/cascade-select';
initCascadeSelect();

import toggleHamburgerMenu from '../../src/toggle-hamburger-menu';
toggleHamburgerMenu();